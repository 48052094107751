import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container } from 'layout';
import SaveWaterCard from 'components/SaveWaterCard';
import WithCarousel from 'components/WithCarousel';

import settings from './constants';
import { IGetStartedBlockProps } from './models';

import './GetStartedBlock.scss';

const GetStartedBlock: FC<IGetStartedBlockProps> = ({ getStartedBlock, ariaPrev, ariaNext }) => {
  const { title, cards, carouselMode } = getStartedBlock[0].properties;
  const saveWaterCards = cards.map((card) => ({ ...card.properties }));

  return (
    <div data-testid="GetStartedBlock" className="get-started-block">
      <Container className="get-started-block__wrapper">
        {title && <h3 className="get-started-block__title">{title}</h3>}
        {!carouselMode ? (
          <div className="get-started-block__cards">
            {saveWaterCards.map(({ title: cardTitle, subTitle, text, image }) => (
              <SaveWaterCard
                key={cardTitle}
                title={cardTitle}
                subTitle={subTitle}
                text={text}
                image={image}
              />
            ))}
          </div>
        ) : null}
      </Container>

      {carouselMode ? (
        <WithCarousel
          ariaNext={ariaNext}
          ariaPrev={ariaPrev}
          slideComponent={SaveWaterCard}
          slides={saveWaterCards}
          settings={settings}
        />
      ) : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentGetStartedBlock on TGetStartedBlock {
    properties {
      title
      cards {
        ...FragmentSaveWaterCard
      }
      carouselMode
    }
  }
`;

export default GetStartedBlock;
