import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import MissionBlock from 'components/MissionBlock/MissionBlock';
import SaveWaterBanner from 'components/SaveWaterBanner';
import DivideTextSection from 'components/DivideTextSection';
import GetStartedBlock from 'components/GetStartedBlock';
import TitleText from 'common/TitleText';
import { SaveWaterTypes } from '@shared/types/umbraco';

import './SaveWaterPage.scss';

import '@fontsource/montserrat/600-italic.css';
import '@fontsource/montserrat/300.css';

const SaveWaterPage: FC<SaveWaterTypes.IProperties> = ({
  pageContext: { link, searchUrl },
  data: {
    allSaveWater: { nodes },
  },
}) => {
  const {
    missionBlock,
    banner,
    divideTextSection,
    getStartedBlock,
    defaultCompositions,
    seoMetaKeywords,
    seoMetaTitle,
    seoMetaTitleTemplate,
    seoMetaDescription,
    seoExternalHreflangs,
    seoNoIndex,
    seoCanonicalUrl,
    alternateUrls,
    ogImageUrl,
    sourcesBlock,
    hideHeader,
  } = nodes?.[0];
  const { waiSettings } = defaultCompositions;
  const imageUrl = ogImageUrl || banner?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = banner?.[0]?.properties?.title;
  
  return (
    <Layout
      {...{
        ...defaultCompositions,
      }}
      seoMetaKeywords={seoMetaKeywords}
      seoMetaTitle={seoMetaTitle}
      seoMetaTitleTemplate={seoMetaTitleTemplate}
      seoNoIndex={seoNoIndex}
      seoMetaDescription={seoMetaDescription}
      seoExternalHreflangs={seoExternalHreflangs}
      seoCanonicalUrl={seoCanonicalUrl}
      skipText={waiSettings?.skipText}
      link={link}
      searchUrl={searchUrl}
      alternateUrls={alternateUrls}
      imageUrl={imageUrl}
      imageAlt={imageAlt}
      isHideHeader={hideHeader}
      pageClassName="save-water-page"
    >
      <SaveWaterBanner banner={banner} />
      <DivideTextSection divideTextSection={divideTextSection} />
      <GetStartedBlock
        getStartedBlock={getStartedBlock}
        ariaPrev={waiSettings.ariaPrev}
        ariaNext={waiSettings.ariaNext}
      />
      <MissionBlock missionBlock={missionBlock} />
      {sourcesBlock?.[0]?.properties ? (
        <div className="save-water-page__sources">
          <TitleText data={sourcesBlock[0].properties} />
        </div>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query SaveWaterPageQuery($link: String) {
    allSaveWater(filter: { link: { eq: $link } }) {
      nodes {
        missionBlock {
          ...FragmentMissionBlock
        }
        banner {
          ...FragmentSaveWaterBanner
        }
        divideTextSection {
          ...FragmentDivideTextSection
        }
        getStartedBlock {
          ...FragmentGetStartedBlock
        }
        sourcesBlock {
          ...FragmentTitleText
        }
        defaultCompositions {
          footer {
            ...FragmentFooter
          }
          waiSettings {
            skipText
            ariaPrev
            ariaNext
          }
          header {
            ...FragmentHeader
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
          promoPlatformForm {
            ...FragmentPromoPlatformForm
          }
        }
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        seoCanonicalUrl {
          url
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        hideHeader
      }
    }
  }
`;

export default SaveWaterPage;
